import * as React from "react"
import { Fragment } from "react"
import PropTypes from "prop-types"

const ResourceInfos = ({resourceName, labelName, timeToRead=0, formattedDate, author="", classNames="" }) => {
  return (
    <div className={`label-${labelName !== null ? labelName.toLowerCase() : 'empty'}-wrapper resource-infos ${classNames}`}>
      <span className="label-dot"></span>
      <span className="label-name">{labelName}</span>
      <span className="resources_text-separation"> | </span>
      <span>{
        resourceName === 'Blogs' ?
        `${timeToRead} min lezen`
        : formattedDate
        }</span>
      {author !== "" ? 
        <Fragment><span className="resources_text-separation"> | </span><span>{`Geschreven door ${author.split(' ').slice(0, -1).join(' ')} `}</span></Fragment>
      : null
      }
    </div>
  )
}

ResourceInfos.propTypes = {
  labelName: PropTypes.string,
  resourceName: PropTypes.string,
  timeToRead: PropTypes.number,
  formattedDate: PropTypes.string,
  author: PropTypes.string
}

export default ResourceInfos
