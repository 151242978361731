import React, { Fragment } from "react"
import { graphql } from "gatsby"
import Layout from "../../../components/layout"
import Seo from "../../../components/seo"
import { Container } from "react-bootstrap"
import Introduction from "../../../components/pageIntroduction"
import RelatedResources from "../../../components/relatedResources"
import Cta from "../../../components/cta"
import Favicon from "../../../images/favicon.png"

const ThankYouPage = ({ data, ...props }) => {
  const thankYou = data.strapiWebinars
  const allWebinars = data.allStrapiWebinars.nodes
  const relatedWebinars = allWebinars
    .filter(webinar => {
      return webinar.strapiId !== thankYou.strapiId
    })
    .slice(0, 3)
  const today = new Date().toISOString()

  return (
    <Layout>
      <Container className="page resources">
        <Seo
          title={
            thankYou.seo !== null
              ? thankYou.seo.meta_title !== null
                ? thankYou.seo.meta_title
                : "Geen meta titel"
              : ""
          }
          image={{
            src: thankYou.seo.meta_image?.image?.localFile?.publicURL
              ? thankYou.seo.meta_image?.image?.localFile?.publicURL
              : null,
          }}
          description={
            thankYou.seo !== null
              ? thankYou.seo.meta_description !== null
                ? thankYou.seo.meta_description
                : ""
              : ""
          }
          pathname={props.location.pathname}
          favicon={Favicon}
        />
        {thankYou.webinar_date >= today ? (
          <Introduction
            title={
              thankYou.thank_you_page !== null
                ? thankYou.thank_you_page.Title
                : "Geweldig! Tot ziens op het webinar!"
            }
            subtitle={
              thankYou.thank_you_page !== null
                ? thankYou.thank_you_page.Subtitle
                : null
            }
          />
        ) : (
          <Fragment>
            <Introduction
              title={
                thankYou.thank_you_page !== null
                  ? thankYou.thank_you_page.Title
                  : "Bekijk de webinar hieronder:"
              }
              subtitle={
                thankYou.thank_you_page !== null
                  ? thankYou.thank_you_page.Subtitle
                  : "We hebben ook de link naar je e-mail gestuurd om op een ander moment terug te kijken."
              }
            />
            <div className="video_wrapper pb-5">
              <div className="video text-center">
                <iframe
                  width="1120"
                  height="630"
                  src={thankYou.webinar_url}
                  title={thankYou.title}
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          </Fragment>
        )}
        <RelatedResources
          title="Onze webinars"
          data={relatedWebinars}
          name="Webinars"
          padding={{ bottom: 0, top: 80, left: 15, right: 15 }}
        />
        <Cta
          verticalStyling={false}
          title=""
          intro="Doelstellingen vertalen naar design, development en marketing."
          link="/discovery"
          linkText="Ontdek de mogelijkheden"
          titleSize="h2"
          className="resources-cta"
        />
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query ThankYouWebinarQuery($slug: String!) {
    strapiWebinars(slug: { eq: $slug }) {
      strapiId
      title
      slug
      webinar_date
      webinar_url
      thank_you_page {
        Title
        Subtitle
      }
      seo {
        id
        indexable
        meta_description
        meta_keywords
        meta_title
        meta_image {
          meta_image_alt
          image {
            localFile {
              absolutePath
              publicURL
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
    allStrapiWebinars(sort: { fields: published_at, order: DESC }) {
      nodes {
        id
        title
        strapiId
        slug
        updated_at(formatString: "DD MMMM YYYY", locale: "nl")
        webinar_date
        webinar_date_formatted: webinar_date(
          formatString: "DD MMMM YYYY"
          locale: "nl"
        )
        webinar_url
        published_at(formatString: "DD MMMM YYYY", locale: "nl")
        created_at(formatString: "DD MMMM YYYY", locale: "nl")
        labels {
          color
          id
          name
          slug
        }
        featured_image {
          url
          alternativeText
          localFile {
            absolutePath
            publicURL
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`

export default ThankYouPage
